import React from "react";
import "./Reviews.css";

function Reviews() {
  return (
    <div className="content" id="Reviews">
      <div className="heading">
        <h2 className="text-ab-hh">Отзывы</h2>
      </div>
      <div className="container container-reviews">
      <div style={{ width: '560px', height: '800px', overflow: 'hidden', position: 'relative' }}>
          <iframe
            style={{ width: '100%', height: '100%', border: '1px solid #e6e6e6', borderRadius: '8px', boxSizing: 'border-box' }}
            src="https://yandex.ru/maps-reviews-widget/69695005530?comments"
          ></iframe>
          <a
            href="https://yandex.ru/maps/org/alyona_troshina_permanentny_makiyazh/69695005530/"
            target="_blank"
            style={{ boxSizing: 'border-box', textDecoration: 'none', color: '#b3b3b3', fontSize: '10px', fontFamily: 'YS Text,sans-serif', padding: '0 20px', position: 'absolute', bottom: '8px', width: '100%', textAlign: 'center', left: '0', overflow: 'hidden', textOverflow: 'ellipsis', display: 'block', maxHeight: '14px', whiteSpace: 'nowrap', padding: '0 16px', boxSizing: 'border-box' }}
          >
            Алёна Трошина - Перманентный макияж на карте Чехова — Яндекс Карты
          </a>
        </div>
      </div>
    </div>
  );
}

export default Reviews;
