import React from "react";
import "./Me.css";

function Me() {
  return (
    <div className="info-me">
          <p className="info-tg info--me">
            website from:{" "}
            <a
              class="href-s"
              href="https://t.me/maksim_medvedew"
              target="_blank"
              rel="noopener noreferrer"
            >
              @maksim_medvedew
            </a>
          </p>
        </div>
  );
}

export default Me;
