import React from 'react';
import './Navigation.css'

function Navigation() {
  return (
    <nav className="menu-nav">
      <ul className="menu-ul">
        <li className="li"><a className="a" href="#ServicesContent">Услуги</a></li>
        <li className="li"><a className="a" href="#AboutMe">Обо мне</a></li>
        <li className="li"><a className="a" href="#Main">Запись</a></li>
        <li className="li"><a className="a" href="#Questions">Вопросы</a></li>
        <li className="li"><a className="a" href="#Reviews">Отзывы</a></li>
        <li className="li"><a className="a" href="#Footer">Контакты</a></li>
      </ul>
    </nav>
  );
}

export default Navigation;